import * as React from 'react';

import LogoLI from '../images/linkedin-box-line.svg';
import LogoGH from '../images/github-line.svg';

import '../css/reset.css';
import '../css/general.css';
import '../css/index.css';

const IndexPage = () => {
  return (
    <main id="full-page">
      <header className="header-container d-flex">
        <div className="header-content">
          <h1 className="sans-serif intro" id="title">
            Hi there!
          </h1>
          <h2 className="intro bio" id="bio">
            I'm Giuliana Ferraro and I'm a Software Engineer. I like building
            web apps with Ruby on Rails, JavaScript, and React.
          </h2>
          <a
            className="btn center intro"
            href="mailto:hey@giulianaferraro.com"
            id="cta"
          >
            Let's Chat
          </a>
          <div className="d-flex intro" id="social">
            <a
              href="https://github.com/gferrarocamus"
              title="GitHub"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoGH />
            </a>
            <a
              href="https://www.linkedin.com/in/giulianaferraro/en"
              title="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoLI />
            </a>
          </div>
        </div>
      </header>
    </main>
  );
};

export default IndexPage;

export const Head = () => (
  <>
    <meta charSet="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
    <meta httpEquiv="Cache-Control" content="max-age=1000" />
    <title>Giuliana Ferraro | Software Engineer</title>
    <meta
      name="description"
      content="Hey! My name is Giuliana Ferraro and I'm a software engineer."
    />
  </>
);
